/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $GetAccreditationInfoResponse = {
  properties: {
    accreditationDocuments: {
      type: 'array',
      contains: {
        type: 'AccreditationDocument',
      },
      isRequired: true,
    },
    accreditationAnswers: {
      type: 'array',
      contains: {
        type: 'AccreditationAnswer',
      },
      isRequired: true,
    },
    accreditationQuestions: {
      type: 'array',
      contains: {
        type: 'AccreditationQuestion',
      },
      isRequired: true,
    },
    accreditationStatus: {
      type: 'AccreditationStatuses',
      isRequired: true,
    },
    accreditationId: {
      type: 'string',
      isRequired: true,
    },
  },
} as const;
